@import '../../scss/colors';

.hotel-lookup-list {
    width: 100%;
    z-index: 999998;
    background-color: #fff;
    .hotel-lookup-result:hover {
        cursor: pointer;
        background-color: rgba($color-teal, .25);
        #hotel-name {
            text-decoration: underline;
        }
    }
}