.react-autosuggest__container {
	input {
		width: 80%;
	}
	.react-autosuggest__suggestions-container {
		width: 30.8%;
		font-size: 14px;
		border: 1px solid #ccc;
		position: absolute;
		background: #fff;
		padding: 0 2px;
        z-index: 99999;

		ul {
			list-style: none;
			padding: 0;
			margin: 5px 0;

			li {
				margin: 5px 0;
			}
			&:hover {
				cursor: pointer;
			}
		}
	}
}

.react-autosuggest__suggestion {
    background-color: #fff;
}
.react-autosuggest__suggestion--highlighted {
    background-color: $color-teal-light;
}