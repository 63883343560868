.pagination {
  margin: 35px 0;
  padding: 0;

  list-style-type: none;
  text-align: center;
  font-size: 14px;

  li {
    display: inline-block;
    width: 30px;
    height: 30px;

    line-height: 30px;
    border: 1px solid #CCC;
    border-right: none;
    color: #628ea8;
    cursor: pointer;

    &:last-child {
      border-right: 1px solid #CCC;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &.active {
      color: #FFF;
      background: #628ea8;
    }

    a {
      display: inline-block;
      width: 100%;
    }
  }

  .break-me {
    a {
      color: #628ea8;
      text-decoration: none;
    }
  }

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
