@import '../../scss/colors'; 

.nav-pills > li > a {
    border-radius: 0;
 }
 
 #app-container {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    overflow: hidden;
 }
 
 #app-container.sidebar-open {
    padding-left: 250px;
    overflow: hidden;
 }

 #role-selector-container {
    z-index: 2001;
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    width: fit-content;
    margin: auto;
 }

 #toggle-container {
    z-index: 1010;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    button {
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }
 }
 
 #sidebar-container {
    z-index: 1001;
    position: fixed;
    left: 250px;
    width: 0;
    height: 100%;
    margin-left: -250px;
    overflow-y: auto;
    overflow-x: hidden;
    background: $color-blue-dark;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    .logo {
        width: 84px;
        margin-left: -13px;
        margin-top: -13px;
        .fa-user-secret {
            font-size: 3rem;
            margin-left: 1.85rem;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
            color: $color-orange;
        }
    }

    #upgrade-button {
       font-size: x-small;
       padding: 0;
       height: 1.75rem;
    }
 }
 
 #app-container.sidebar-open #sidebar-container {
    width: 240px;
 }
 
 #app-container.sidebar-open {
    #toggle-container {
      width: 240px;
    }
    #content-container {
      width: 100%;
    }
 }
 
 #app-container.sidebar-closed {
    #toggle-container {
      width: 40px;
    }
    #content-container {
       width: 100%;
    }
 }
 
 #content-container {
    min-height: 100vh;
    position: absolute;
    padding: 15px;
    overflow-x: hidden;
 }
 
 #content-container.with-local-nav {
    width: 97%;
 }

 #view-container {
    padding-top: 1rem;
 }
 
 #app-container.sidebar-open #content-container {
    position: relative;
    margin-right: 0px;
 }
 
 .fixed-brand {
    width: auto;
 }
 /* Sidebar Styles */
 
 .sidebar-nav {
    position: absolute;
    width: 240px;
    margin: 0;
    padding: 0;
    margin-top: 2px;
 }
 
 .sidebar-nav.top {
    top: 0;
    margin-top: 2px;
    background-color: $color-blue-dark;
    z-index: 1004;
 }
 
 .sidebar-nav.bottom{
    bottom: 0;
    margin-bottom: 2px;
    z-index: 1003;
 }
 
 .sidebar-nav li {
    text-indent: 15px;
    line-height: 40px;
 }
 
 .sidebar-nav li a {
    display: block;
    text-decoration: none;
    color: #999999;
 }
 
 .sidebar-nav li a:hover {
    text-decoration: none;
    color: #fff;
    background: rgba(255, 255, 255, 0.2);
    border-left: red 2px solid;
 }
 
 .sidebar-nav li a:active,
 .sidebar-nav li a:focus {
    text-decoration: none;
 }
 
 .sidebar-nav > .sidebar-brand {
    height: 65px;
    font-size: 18px;
    line-height: 60px;
 }
 
 .sidebar-nav > .sidebar-brand a {
    color: #999999;
 }
 
 .sidebar-nav > .sidebar-brand a:hover {
    color: #fff;
    background: none;
 }
 
 .no-margin {
    margin: 0;
 }
@media (min-width: 768px) {
	#app-container.sidebar-closed {
      padding-left: 175px;
      #sidebar-container {
         width: 50px;
         .logo {
            width: 78px;
         }
      }
      #content-container {
         padding-top: 0px;
         padding-left: 20px;
         padding-right: 20px;
         position: relative;
         -webkit-transition: all 0.5s ease;
         -moz-transition: all 0.5s ease;
         -o-transition: all 0.5s ease;
         transition: all 0.5s ease;
         width: 122%;
         margin-left: -150px;
         #view-container {
            margin-left: 20px;
         }
         .view-content {
            margin-left: -20px;
         }
         .view-header {
            margin-left: -40px;
            width: 100%;
         }
      }
      #content-container.with-local-nav {
         left: 190px;
         width: 100%;
         .view-header {
            width: 105%;
         }
         #view-container {
            margin-left: 0px;
         }
      }
   }
   #toggle-container {
      display: none;
   }
   .fixed-brand {
      width: 240px;
   }
 }
 
@media (min-width: 1025px) {
	#app-container.sidebar-closed {
      padding-left: 240px;
      #sidebar-container {
         width: 64px;
         .logo {
            width: 84px;
          }
      }
      #content-container {
         width: 110%;
         margin-left: -150px;
         .view-content {
            width: calc(100% - 200px);
         }
         #view-container {
            margin-left: auto;
         }
      }
      #content-container.with-local-nav {
         left: 230px;
         width: 98%;
         margin-left: -190px;
         .view-header {
            width: 100%;
         }
         #view-container {
            margin-left: 15px;
         }
      }
   }
}