
@import './utils/normalize.css';
@import './utils/react-notifications.css';

@import 'mixins';
@import './colors';
@import 'variables';
$font-family-sans-serif:      "Poppins", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base:            $font-family-sans-serif;

$primary: $color-teal-dark;
$secondary: $color-gray-dark;
$success: $color-teal;
$warning: $color-yellow;
$danger: $color-salmon;
$info: $color-slate;
$light: $color-gray-medium;
$dark: $color-blue-dark;

@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/react-quill/dist/quill.core.css';
@import '../../node_modules/react-quill/dist/quill.snow.css';

@import './sizes';

@import './common.scss';
@import './global.scss';

@import './utils/skeleton.css';
@import 'icons';
@import 'button/index';
@import 'form/index';
@import 'rating/index';
@import 'pagination/index';

/* compile time errors when chunking */
@import '../../node_modules/slick-carousel/slick/slick.css';
@import '../../node_modules/slick-carousel/slick/slick-theme.css';
@import '../../node_modules/react-date-range/dist/styles.css';
@import '../../node_modules/react-date-range/dist/theme/default.css';
/* */

@import 'autosuggest/index';
@import 'checkbox/index';
@import 'contractclauses/index';
@import 'datetime/date-range-picker.scss';
@import 'datetime/index';
@import 'events-list/index';
@import 'footer/index';
@import 'header/index';
@import 'hotel/profile';
@import 'hotel/search-result.scss';
@import 'hub-invitation/index';
@import 'item/index';
@import 'item-view/index';
@import 'my-profile/index';
@import 'modals/request-quotes';
@import 'modals/event-quotes-submit';
@import 'organization/index';
@import 'photo-control/index';
@import 'planner/index';
@import 'promotions/promotion-details-modal.scss';
@import 'promotions/promotion-details.scss';
@import 'proposal/index';
@import 'quote/index';
@import 'tag-editor/index';
@import 'toolbar/index';
@import 'view/index';

@import 'core/layout';
@import 'core/grid';
@import 'core/nav';
@import 'core/sidebar';

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: $color-gray-medium;
}
::-moz-placeholder {
	/* Firefox 19+ */
	color: $color-gray-medium;
}
:-ms-input-placeholder {
	/* IE 10+ */
	color: $color-gray-medium;
}
:-moz-placeholder {
	/* Firefox 18- */
	color: $color-gray-medium;
}

.wrapper {
	min-height: 100vh;
}

.main {
	max-width: 100%;
	margin-top: 10px;
	padding: 10px;
	font-family: 'Inter var', 'Open Sans', sans-serif;
}

//1024 and lower
html {
	font-size: 12px;
}
@media (min-width: 1800px) {
	html {
		font-size: 14px;
	}
}

@media (min-width: 550px) {
	.main { margin-top: 100px; }
}

.container {
	max-width: 100%;
}

.fade-to-in {
	opacity: 1;
	transition: 1s;
}
.fade-to-out {
	opacity: 0;
	transition: 1s;
}
body.designer {
	.row {
		border: 1px dashed $color-gray-medium;
	}
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
		border: 1px dashed $color-gray-medium;
	}
}
div.footer-spacer {
	height: 5rem;
}
#nav-model-selector-container {
    z-index: 2001;
    position: absolute;
    top: 1px;
    right: 0;
    width: -moz-fit-content;
    width: fit-content;
 }
 .form-control.is-invalid {
	 border-color: #ced4da;
 }
 .form-control.is-invalid:focus {
	 border-color: #0ddbfb;
	 box-shadow: 0 0 0 0.2rem rgba(2, 117, 135, .25);
	 outline: 0;
}
.metric-icon {
	font-size: 2rem;
	border-radius: 15%;
	padding: 0.25rem;
	&.danger {
		color: $color-red;
		background-color: rgba($color-red, 0.2);
	}
	&.warning {
		color: $color-yellow;
		background-color: rgba($color-yellow, 0.2);
	}
	&.primary {
		color: $color-teal-dark;
		background-color: rgba($color-teal-dark, 0.2);
	}
	&.success {
		color: $color-teal-medium;
		background-color: rgba($color-teal-medium, 0.2);
	}
}
#currency-picker-container {
    text-align: inherit;
    .form-control {
        text-align: inherit;
    }
}
.modal-dialog.currency-picker {
    .list-group {
        height: 25rem;
        overflow-y: scroll;
    }
}
.image-carousel-container {
    .row {
        margin-bottom: .5rem;
        .btn {
            width: 8rem;
            font-weight: bold;
        }
    }
    .counter {
        text-align: center;
        font-weight: bold;
        font-size: 1.2rem;
    }
    .slick-slider {
        .slick-list {
            margin-left: 4rem;
            margin-right: 4rem;
        }
        .slick-next {
            margin-right: 1.75rem;
        }
        .slick-prev::before,.slick-next:before {
            font-size: 2.5rem;
            color: $color-teal-dark;
        }
    }
    .image-caption {
        font-size: large;
        font-weight: bold;
        text-align: center;
    }
    .carousel-image {
        img {
            max-width: 600px;
            border-radius: .5rem;
            height: 38rem;
        }
        .legend {
            font-size: xx-large;
            z-index: 12;
        }
    }
    .thumbnail-container {
        overflow-x: scroll;
        .thumbnail-table {
            .thumbnail-cell {
                cursor: pointer;
                text-align: center;
            }
            .thumbnail-cell.current {
                cursor: unset;
                border-radius: .5rem;
                border-top: 2px solid $color-teal-dark;
            }
            img {
                border-radius: .5rem;
                width: 90px;
            }
        }
    }
}
div.anchor-mimic {
    cursor: pointer;
    color: $color-teal-dark;
    &:hover {
        text-decoration: underline;
    }
}