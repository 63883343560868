.input,
.react-datepicker__input-container input {
  height: 50px;
  padding: 6px 12px;

  font-size: $font-size-m;
  line-height: $font-line-height;
  color: $color-black;

  vertical-align: middle;
  border: 1px solid $color-gray-light;
  border-radius: 4px;
  background-image: none;
  box-shadow: none;
  box-sizing: border-box;

  &.error {
    border: 1px solid red;
  }
}
