.navbar {
  padding: unset;
  .logo {
    width: 12rem;
  }
  .pro-trial {
    font-size: x-large;
    font-weight: bold;
    color: $color-yellow;
    margin-right: 2rem;
    width: 7rem;
  }
  button {
    margin-right: 2rem;
    font-weight: bold;
    color: white;
  }
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: $navbar-height;
  z-index: 9;
  font-family: 'Inter var', sans-serif;
  background-color: $color-white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
  border-bottom: none;

   &__wrapper {
    padding: 4px 0;
  }

  .valign-middle {
    margin-top: 1em;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  &__list-sub {
    display: none;
    position: absolute;
    margin: 20px 0 0 -20px;

    background: $color-white;
    text-align: left;

    li {
      display: block;
      padding: 15px 20px;
      min-width: 200px;

      a {
        transition: all 0.3s ease-in-out;
        text-transform: none;
        font-size: $font-size-s;
        color: $color-gray-medium;
        letter-spacing: 0.5;
      }
    }

    li:hover {
      a {
        color: $color-teal;
        padding-left: 10px;
      }
    }
  }

  &__list:first-child,
  .row .two {
    text-align: right;
  }

  &_list-item {
    position: relative;
    display: inline-block;
    padding: 29px 20px;

    a {
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.5px;
      color: $color-blue-dark;
      text-decoration: none;
    }

    .fa {
      position: absolute;
      right: -2px;

      transform: rotate(90deg);
    }
  }

  &_list-item:hover > a {
    color: $color-teal;
  }

  &_list-item:hover {
    .fa {
      transform: rotate(-90deg);
    }
  }

  &_list-item:hover > ul {
    display: block;
  }

  &__auth {
    margin: 13px 0;
    position: fixed;
    right: 25px;
  }

  &__cart {
    display: none;
    position: relative;
    margin: 20px 0;
  }

  &__cart-amount {
    position: absolute;
    right: -8px;
    top: -8px;

    width: 20px;
    height: 20px;

    text-align: center;
    border-radius: 10px;
    background-color: $color-orange;
    font-size: 11px;
    line-height: 20px;
    color: $color-white;
  }

  &__logged-menu-btn {
    margin: 22px 0;
    position: fixed;
    right: 25px;
  }

  &__logged-menu {
    position: absolute;
    margin: 0;
    padding: 10px 20px;
    margin: 2px 0px 0px -65px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 9px;

    list-style-type: none;
    background: #FFF;
    border: 1px solid #EEE;
    text-align: left;
    .search-text{
      display: none;
    }
    .search-icon{
      display:none;
    }
    li {
      a {
        display: inline-block;
        padding: 10px 0;

        text-decoration: none;
        color: #628ea8;
        font-size: 15px;

        &:hover {
          color: #00a1f1;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 92px;

      width: 20px;
      height: 20px;

      background: #FFF;
      transform: rotate(45deg);
      border-top: 1px solid #EEE;
      border-left: 1px solid #EEE;
    }
  }

  .logo {
    position: fixed;
    left: 0px;
    display: inline-block;
    width: 200px;
    height: 100px;
    margin-left: 15px;
    background-image: url(/images/logo-min.png);
    background-size: 100%;
    background-repeat: no-repeat;
  }
}

@media (max-width: 960px) { 
  .header{
    height: $navbar-height;
    &__logged-menu{
      margin: -2px 0px 0px -85px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
      .search-text{
        display: inline-grid;
        padding-left: 5px;
      }
      .search-icon{
        display:inline;
      }
    }
    &__logged-menu:before{
      left: 92px;
    }
    .one-column .align-right{
      height: 65px;
      margin-left: 0px;
    }
    .one-column .align-center{
      height: 65px;
      margin-left: 0px;
    }
    .nine-columns {
      height: 65px;
      width: 10%;
    }
    .logo{
      width: 75px;
      height: 60px;
      margin-top: 6px;
    }

  }
   .header__list{
    visibility: hidden;
    overflow: hidden;
    white-space: nowrap;
      }


}
@media (max-width: 550px)  {
  .header{
    height: $navbar-height;
    &__logged-menu{
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
      width: 100%;
      position: fixed;
      right: 0;
      float: left;
      list-style: none;
      background-color: rgba(51,73,93,.95);
      padding: 0px;
      transition: 0.5s;
      
      .search-icon{
        color: white;
      }
      li{
        padding: 7px 35px;
        border-top: 1px #628EA8 solid;
      }
      li:first-child{
        border-top:none;
      }
      li a{
        color: white;
      }
    }
    &__logged-menu:before{
    visibility: hidden;
    }
    
    &__cart {
      display: inline-block;
      position: relative;
      margin: 20px 0;
      left: 85px;
    }

    &__auth {
      display: none;
    }

  .one.columns{
  width: 10%;
  margin-left: 15px;
    }
  .nine.columns{
    width: 10%;
    }
    .one.column.align-right{
      height: 65px;
      margin-left: 0px;
      width: 60%;
    }
    .one.column.align-center{
      height: 65px;
      margin-left: 0px;
      width: 10%;
      text-align: right;
    }
  }
}