@import '../../scss/colors';

//standalone chat
div#chat-container {
    position: relative;
    height: 100vh;
    .chat-header {
        background-color: $color-gray-light;
        border-bottom: 1px solid $color-gray-medium;
        height: 44px;
        width: 100vw;
        padding: 2px;
        .badge {
            position: absolute;
            top: 2px;
            right: 2px;
        }
    }
}

div#chat-conversation-container {
    position: relative;
    width: 100%;
    height: calc(100% - 44px);
    .btn.chat-conversation-popout {
        position: absolute;
        right: 1rem;
        top: 0;
        font-size: smaller;
        background-color: #fff;
        z-index: 101;
    }
    div.chat-conversation-title {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        font-weight: bold;
        color: $color-teal-dark;
        background-color: #fff;
        z-index: 100;
    }
    div.conversation-messages {
        padding-top: 1rem;
        width: 100%;
        height: calc(100% - 120px);
        overflow-y: scroll;
        z-index: 99;
        padding-left: 1rem;
        padding-right: 0;
        div#messages {
            padding-top: 0;
        }
        div.chat-empty-message {
            color: $color-orange;
            margin-top: 1rem;
            font-size: large;
            font-weight: bold;
        }
    }
    div.conversation-tools {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 120px;
        background-color: $color-gray-light;
        border: 1px solid $color-gray-medium;
        div.conversation-tools-header {
            padding: .5rem;
            font-weight: bold;
            font-size: smaller;
            background-color: $color-gray-light;
            border: 1px solid $color-gray-medium;
        }
        textarea.chatMessageInput {
            padding: .25rem;
            width: 75%;
            height: 60%;
            border: none;
            background-color: inherit;
            resize: none;
        }
        button {
            position: absolute;
            top: 3.3rem;
            right: 0.5rem;
            width: 5rem;
            height: 3rem;
        }
    }
}

// larger than phones (popout window)
// @media (min-width: 426px) {
//     div#chat-conversation-container {
//         height: 96%;
//     }
// }

// larger than phones and popout window
@media (min-width: 435px) {
    div#chat-conversation-container {
        height: 100%;
        div.conversation-messages {
            padding-left: unset;
            padding-right: 0;
            height: 80%;
            div#messages {
                padding-top: 1rem;
            }
        }
        div.conversation-tools {
            height: 20%;
            textarea.chatMessageInput {
                height: 74%;
                width: 91%;
            }
            button {
                position: absolute;
                top: 4rem;
                right: 0.5rem;
                width: 5rem;
                height: 3rem;
            }
        }
    }        
}
@media (min-width: 768px) {
    div#chat-conversation-container {
        div.conversation-tools {
            textarea.chatMessageInput {
                width: 85%;
            }
        }
    }        
}
@media (min-width: 1025px) {
    div#chat-conversation-container {
        div.conversation-tools {
            textarea.chatMessageInput {
                width: 91%;
            }
        }
    }        
}