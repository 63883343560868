.event-quotes-submit {
  width: 30% !important;
  padding: 30px !important;
  min-width: 20%;

  color: $color-blue-dark;

  .icon {
    margin-right: 10px;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 4px;

    cursor: pointer;
  }

  &__header-txt {
    font-size: 18px;
    font-weight: bold;
  }
  &__header{
    padding-top: 20px;
  }

  &__order-num {
    padding: 14px 0;
    font-size: 18px;
  }

  &__title {
    margin: 20px 0;
  }

  &__txt {
    line-height: 1.5;
    font-size: 15px;

    a {
      color: $color-teal;
      text-decoration: none;
    }
  }
}
@media (max-width: 550px) { 
  .event-quotes-submit {
    width: 60% !important;
    &__header{
      padding-top: 20px;
    }
    &__txt{
      font-size: 12px;
    }    
  &__order-num{
    font-size: 14px;
    }
  }
}