/* Notifications position reset
.notification-container {
  top: auto;
  bottom: 12px;
}
 */
.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.valign-middle {
  vertical-align: middle;
}

.view-more {
  padding: 0 2px;

  color: $color-teal-dark;
  text-decoration: none;
}

.text_color_blue {
  color: $color-slate;
}

.tab-title {
  font-size: x-large;
  font-weight: bold;
  margin-bottom: .5rem;
}

.tab-subtitle {
  font-size: large;
  margin-bottom: .5rem;
}

.tab-content {
  margin-top: -1px;
  background: #fff;
  border: 1px solid #ddd;
  .tab-pane {
    padding: 15px;
  }
}

.loader {
  img {
    width: 100px;
  }
}

.loader {
  &_size_s {
    img {
      width: 30px;
    }
  }
}

.hidden {
  display: none;
}

.layer_index-9 {
  position: relative;
  z-index: 9;
}

.mr-10 {
  margin-right: 10px;
}

.no-decoration {
  text-decoration: none;
}

.modal-title.h4 {
  font-weight: bold;
}

.modal-40w {
  width: 40%;
  max-width: none!important;
}

.modal-50w {
  width: 50%;
  max-width: none!important;
}

.modal-60w {
  width: 60%;
  max-width: none!important;
}

.modal-70w {
  width: 70%;
  max-width: none!important;
}

.modal-80w {
  width: 80%;
  max-width: none!important;
}

.modal-90w {
  width: 90%;
  max-width: none!important;
}
div.readmore {
  cursor: pointer;
  background: -webkit-linear-gradient(#000, #fff);
  height: 2.4rem;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
div.readless {
  height: unset;
}
div.readmorebutton {
  cursor: pointer;
  color: $color-gray-dark;
  text-align: center;
}