.item {
  display: inline-block;
  width: 31.333%;
  margin: 10px 1%;
  border: 1px solid $color-gray-light;
  box-sizing: border-box;
  border-radius: 6px;
  
  &__mespace.row {
    min-height: 57px;
  }

  &__card-footer{
    height: 75px;
    padding: 5px 0;
  }
  &__info-bottom{
    border-top: 1px solid $color-gray-light;
  }
  &__price-info{
    color: $color-teal;
    margin: -4px 0px -10px 0px;
  }
  &__price-box{
    padding: 0px;
  }
  &__price-header{
    font-size: 12px;
    margin: 0px;
  }
  &__price-footer{
    font-size: 12px;
    margin: 0px;
  }

  &__thumb-wrapper {
    height: 230px;

    overflow: hidden;
  }

  &__thumb {
    width: 100%;
  }
   &__info-header {
    margin: 0px 0px 8px 0px;
    font-size: 18px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &__info-distance {
    margin: 0px 0px 8px 0px;
    font-size:16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }

  &__icons {
    padding: 7px 0 0 0;

    .icon {
      padding: 0 5px;
    }
  }
  .button_status_added {
    color: $color-salmon;
    border-color: $color-salmon;
    background-color: $color-white;
  }
  .button_status_added:hover {
    color: $color-salmon;
    border-color: $color-salmon;
    background-color: $color-white;
  }

  .loader {
    display: inline-block;
  }

  .dv-star-rating {
    margin-top: 5px;
  }
}

/* Image Carousel Temporary*/
.slick-slide img{
  object-fit: cover;
  height: 230px;
  width: 100%;
}
.slick-prev{
  z-index: 1;
  left: 3px !important;
  text-shadow: 1px 1px 4px black;
}
.slick-next{
  
  right: 3px !important;
  text-shadow: 1px 1px 4px black;
}
.legend{
  z-index: 0;
  color: white;
  position: absolute;
  top: 1px;
  padding-left: 8px;
  font-size: 12px;
  text-shadow: 1px 1px 4px black;
}

@media (max-width: 1614px) {
  .item{
    &__mespace.row {
      min-height: 81px;
    }
  }
}

@media (max-width: 1280px) {
  .item{ 
      &__thumb-wrapper {
    max-height: 190px;
    overflow: hidden;
  }
  &__info-header {
    font-size: 14px;
  }
  &__info-distance{
    font-size: 12px;
    }
  .icon{
    font-size: 18px;
    }
  }
}
@media (max-width: 1180px) {
   .button__text{
    font-size: 10px;
   }
  }
@media (max-width: 960px) { 
  .item{
    width: 48%;
    &__info-header {
      font-size: 16px;
    }
    &__info-distance{
      font-size: 14px;
      }
    .icon{
      font-size: 23px;
      }
    }
    .button__text{
      font-size: 14px;
     }
 }

 
@media (max-width: 800px) {
  .item{ 
  .icon{
    font-size: 18px;
    }
  }
  .button__text{
    font-size: 10px;
   }
}
@media (max-width: 636px) {
  .item {
    width: 100%;
    .icon{
      font-size: 23px;
      }
  }
  .button__text{
    font-size: 14px;
   }
}
