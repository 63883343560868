@import '../../../scss/colors';

#events-container {
    border: none;
    position: relative;
    margin-left: 1rem;
    padding: .5rem;
    .title {
        font-size: 1.1rem;
        font-weight: bold;
        svg {
            font-size: 1.5rem;
            margin: .5rem .5rem 0 .5rem;
        }
    }
    .view-by-label {
        font-weight: 600;
    }
}