.tag-editor {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ccc;
    padding: 0;
    line-height: 1.5rem;
    white-space: nowrap;
    .btn {
        padding-right: 4px;
        padding-top: 4px;
    }
    .tag {
        background-color: $color-teal-light;
        padding: 0 0 0 .5rem;
        margin: .5rem;
        border-radius: 0.35rem;
    }
    .fa-square-xmark {
        color: $color-red;
    }
    .suggestions {
        display: inline-block;
        width: 12rem;
        .suggestion-list {
            position: absolute;
            width: 12rem;
            border: 1px solid #ccc;
            max-height: 10rem;
            overflow-y: scroll;
            background-color: #fff;
            .suggestion {
                margin: .2rem;
                background-color: #fff;
            }
            .suggestion.active {
                background-color: $color-teal-light;
            }
        }
        .end-adding {
            position: absolute;
            top: 22px;
            right: 40px;
        }
    }
}