.impersonation-watermark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &__text {
    font-size: 48px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.1);
    transform: rotate(-45deg);
    white-space: nowrap;
    text-transform: uppercase;
    letter-spacing: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}
