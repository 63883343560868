.rdt.form-control input {
    border: none;
}

.rdtPicker {
    width: 250px;
}


.rdt.right-align {
    .rdtPicker {
        right: 0;
    }
}

.table .rdtPicker {
    width: auto;
}

.daterangepicker .rdtPicker {
    position: fixed;
}

.daterangepicker .form-control {
    width: 140px;
}

.rdtDay.rdtInside {
    background-color: $color-teal-medium;
}

.daterangepicker svg {
    position: relative;
    font-size: x-large;
    margin-left: 10pt;
    margin-top: 4pt;
    margin-right: 10pt;
}