.checkbox-container {
    display: block;
    position: relative;
    padding-left: 44px;
    padding-top: 4px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1em;
    font-weight: normal !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: .25rem;
    border: 1px solid rgba(#fff, 0);
    height: 1.8rem;
  }
  .checkbox-container.invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" stroke="%23F86866" viewBox="0 0 12 12"%3e%3ccircle cx="6" cy="6" r="4.5"/%3e%3cpath stroke-linejoin="round" d="M5.8 3.6h.4L6 6.5z"/%3e%3ccircle cx="6" cy="8.2" r=".6" fill="%23F86866" stroke="none"/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: left 26px top 0;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  .checkbox-container.invalid + div.invalid-feedback {
    display: block;
    margin-top: -.5rem;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: .05rem;
    left: .05rem;
    height: 25px;
    width: 25px;
    background-color: $color-gray-medium;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    &.small {
      height: 20px;
      width: 20px;
    }
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: $color-gray-light;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: $color-teal-dark;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .checkbox-container .checkmark.small:after {
    left: 7px;
    top: 3px;
  }