@import '../../../scss/colors';

#my-actions-container {
    border: 3px solid rgba($color-dustblue, 0.30);
    position: relative;
    height: 18rem;
    .counter-badge {
        position: absolute;
        font-size: .9rem;
        top: -8px;
        right: -11px;
    }
    .title {
        font-size: 1.1rem;
        font-weight: bold;
        svg {
            font-size: 1.5rem;
            margin: .5rem .5rem 0 .5rem;
        }
    }
    .list-container {
        padding: .5rem;
        .actions-list {
            height: 14rem;
            overflow-y: auto;
            overflow-x: hidden;
            .action-header {
                border: none;
                font-weight: bold;
                color: #000;
                padding: .5rem;
                .btn {
                    font-weight: bold;
                    font-size: .9rem;
                }
            }
            .action-item {
                border: none;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.80);
                padding: .5rem;
                .btn {
                    font-weight: bold;
                    font-size: .9rem;
                }
            }
        }
    }
}