@import '../../scss/colors';

.message-item {
    width: 100%;
    margin-bottom: .5rem;
    .message-container {
        position: relative;
        .message-bubble {
            padding: .5rem;
            border-radius: .5rem;
            display: inline-block;
            max-width: 90%;
            .message {
                font-size: small;
            }
        }
    }
    
    &.incoming {
        .message-container {
            .message-bubble {
                background-color: $color-slate;
                color: #fff;
                svg.fa-circle-user {
                    position: absolute;
                    top: .4rem;
                    left: 1.2rem;
                    font-size: medium;
                }
                .message {
                    margin-left: 1.5rem;
                    .timestamp {
                        margin-left: 1rem;
                    }
                }
                .timestamp {
                    color: $color-gray-light;
                    font-size: x-small;
                    font-weight: bold;
                }
            }
        }
    }

    &.outgoing {
        .message-container {
            text-align: end;
            .message-bubble {
                text-align: start;
                background-color: $color-teal-medium;
                .timestamp {
                    color: $color-gray-dark;
                    font-size: x-small;
                    font-weight: bold;
                }
            }
        }
    }

    .image {
        height: 100%;
        width: 100%;
        object-fit: scale-down;
    }
}

// larger than phones (popout window)
@media (min-width: 426px) {
    .message-item {
        .message-container {
            .message-bubble {
                max-width: 80%;
                .message {
                    font-size: unset;
                }
            }
        }
        &.incoming {
            .message-container {
                .message-bubble {
                    svg.fa-circle-user {
                        font-size: x-large;
                    }
                    .timestamp {
                        font-size: small;
                    }
                }
            }
        }
        &.outgoing {
            .message-container {
                .message-bubble {
                    .timestamp {
                        font-size: small;
                    }
                }
            }
        }
    }
}

// larger than phones and popout window
@media (min-width: 435px) {
    .message-item {
        .message-container {
            .message-bubble {
                max-width: 80%;
            }
        }
    }
}