@import '../../../scss/colors';

.planner-dashboard-container {
    .filters-row {
        margin-bottom: 1rem;
    }
    .text-muted {
        color: $color-gray-600;
    }
    .overflow-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        &.w20r {
            width: 20rem;
        }
        &.w15r {
            width: 15rem;
        }
        &.w85p {
            width: 85%;
        }
    }
    .view-by {
        margin-bottom: 1rem;
        .btn {
            font-size: 1rem;
            width: 7rem;
        }
    }
    .report-card-tools {
        .action-button {
            color: $color-gray-dark;
            background-color: #fff;
            border-color: $color-gray-dark;
            &:focus, &.focus {
                box-shadow: 0 0 0 0.2rem rgba($color-gray-dark, 0.5);
            }
        }
    }
    .rfp-filter-group {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .status-badge {
        padding: .35rem;
        &.danger {
            color: $color-red;
            background-color: rgba($color-red, 0.2);
        }
    }
    .abs-top-right {
        position: absolute;
        top: 4px;
        right: 4px;
    }
    .abs-top-left {
        position: absolute;
        top: 4px;
        left: 4px;
    }
    .abs-bottom-right {
        position: absolute;
        bottom: 0;
        right: 4px;
    }
    .abs-bottom-left {
        position: absolute;
        bottom: 0;
        left: 4px;
    }
    .abs-vcustom-right {
        position: absolute;
        right: 4px;
        margin: auto;
    }
    .right {
        text-align: right;
    }
    .response-rate-container {
        margin-top: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .smaller {
        font-size: 0.8rem;
    }
    .report-card-tools {
        .form-check {
            display: inline;
            margin-left: 1rem;
        }
    }
}