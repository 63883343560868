@import '../../scss/colors';

div.card.data-grid {
    border: unset;

    .card-header {
        background-color: unset;
        .card-title {
            font-size: x-large;
            font-weight: bold;
        }
    }
    thead.sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 69px;
    }
    th {
        position: relative;
        &.dg-header {
            background-color: $color-teal-light;
            padding: .25rem;
            padding-right: 1rem;
            .sort {
                position: absolute;
                top: 0;
                bottom: 0;
                margin-top: auto;
                margin-bottom: auto;
                right: 2px;
                cursor: pointer;
                font-size: medium;
            }
            .sort.active {
                color: $color-teal-dark;
            }
            .sort.inactive {
                color: $color-gray-medium;
            }
        }
        &.dg-filter {
            padding: 0.1rem;
            svg {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                &.danger {
                    color: $color-salmon;
                }
                &.success {
                    color: $color-teal;
                }
            }
        }
    }
    td.blur {
        filter: blur(8px);
    }
    td .btn-link {
        text-align: left;        
    }
    td.noresults {
        text-align: center;
        font-weight: bold;
        padding-top: 2rem;
        font-size: large;
        font-style: italic;
        color: $color-blue-dark;
        height: 5rem;
    }
}