@import '../../scss/colors';

div.report-card-container {
    border: 2px solid rgba($color-gray-medium, .25);
    border-radius: .5rem;
    margin-top: 0;
    margin-right: .5rem;
    margin-left: .5rem;
    margin-bottom: .5rem;
    padding: .5rem;
    padding-left: 1rem;
    position: relative;
    div.report-card-container {
        margin-top: 0;
        margin-right: -0.7rem;
        margin-left: -1.1rem;
        margin-bottom: -0.1rem;
    }
    .report-card-header {
        position: relative;
        .report-card-title {
            font-size: .8rem;
            font-weight: 600;
            margin-bottom: .25rem;
            height: 1.5rem;
            color: $color-gray-dark;
        }
        .report-card-tools {
            position: absolute;
            top: 0;
            right: 0;
        }
        .report-card-tools-middle {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            margin: auto;
            text-align: center;
            width: fit-content;
        }
    }
    .report-card-content {
        position: relative;
        .spinner-border {
            position: absolute;
            margin: auto;
            top: 30%;
            left: 0;
            right: 0;
        }
    }
    .report-card-content.blur {
        filter: blur(8px);
    }
    .report-card-content {
        .blur {
            filter: blur(8px);
        }
    }
    .report-card-metric-container {
        margin-bottom: .5rem;
        .report-card-metric {
            font-size: 1.5rem;
            font-weight: 600;
        }
        .report-card-metric.small {
            font-size: 1.5rem;
        }
        .report-card-metric.danger {
            color: $color-red;
        }
        .report-card-metric.success {
            color: $color-teal-medium;
        }
        .report-card-metric-hint {
            margin-left: .5rem;
            font-size: .8rem;
            color: $color-gray-medium;
        }
        .report-card-metric-hint.large {
            font-size: 1.5rem;
        }
        .report-card-metric-label {
            font-weight: 500;
            margin-top: -0.7rem;
        }
        .report-card-metric-subtext {
            font-weight: 600;
        }
    }
    .report-card-rank-container {
        width: 70%;
        height: 84%;
        border-radius: 50%;
        border: 7px solid $color-teal-dark;
        padding-top: 2rem;
        margin-top: 5%;
        margin-left: 12%;
        text-align: center;
        .report-card-rank {
            font-size: 2rem;
            font-weight: 600;
        }
        .report-card-rank-subtext {
            color: $color-gray-medium
        }
    }
}
div.report-card-container.fit {
    min-height: 7rem;
    .report-card-content {
        min-height: 5.5rem;
    }
}
div.report-card-container.single {
    height: 7rem;
    .report-card-content {
        height: 5.5rem;
    }
}
div.report-card-container.singleHalf {
    height: 9rem;
    .report-card-content {
        height: 7.5rem;
    }
}
div.report-card-container.singlePlus {
    height: 11rem;
    .report-card-content {
        height: 9.5rem;
    }
}
div.report-card-container.double {
    height: 14.5rem;
    .report-card-content {
        height: 12.5rem;
    }
}
div.report-card-container.triple {
    height: 22rem;
    .report-card-content {
        height: 19.5rem;
    }
}
div.report-card-container.huge {
    height: 32rem;
    .report-card-content {
        height: 29rem;
    }
}
@media (min-width: 1025px) {
    div.report-card-container {
        .report-card-header {
            .report-card-title {
                font-size:  1rem;
            }
        }
        .report-card-metric-container {
            .report-card-metric {
                font-size: 2.5rem;
            }
        }
    }
}