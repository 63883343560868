@import '../../../scss/colors';

.kanban-column {
    margin-left: .5rem;
    margin-right: .5rem;
    .kanban-column-header {
        border-radius: 4px;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 1.2rem;
        border-bottom: 4px solid $color-gray-200;
        span.title {
            font-weight: 600;
        }
        span.count {
            margin-left: 2rem;
        }
    }
    .kanban-card {
        position: relative;
        margin-bottom: 0.5rem;
        padding: 0.5rem;
        padding-top: 2.5rem;
        border-radius: 4px;
        border: 1px solid $color-gray-200;
        background-color: #fff;
        min-height: 11.5rem;
        .row {
            margin-bottom: 0.75rem;
        }
        a {
            color: black;
            font-size: 0.9rem;
            font-weight: bold;
        }
        .btn-outline-primary {
            border: none;
        }
    }
}