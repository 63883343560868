.icon {
  font-size: 28px;
}

.icon_style_gulf {
  color: $color-blue-dark;
}

.icon_style_grey {
  color: $color-gray-dark;
}

.icon_style_green {
  color: $color-teal;
}

.icon_style_blue {
  color: $color-gray-medium;
}

.icon_style_red {
  color: $color-orange;
}
.icon_style_white {
  color: $color-white;
}