.photo-container {
    text-align: center;
    padding: .5rem;
    position: relative;
    width: 100%;
    button {
        position: absolute;
        top: 0px;
        left: 0px;
    }
    .photo-add {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
        border-radius: 1.5rem;
        border: 1px solid $color-gray-dark;
        text-align: center;
        font-size: xxx-large;
        color: $color-gray-dark;
        svg {
            position: absolute;
            top: 25%;
            left: 2%;
            width: 100%;
        }
        div {
            position: absolute;
            bottom: 1rem;
            left: 2%;
            width: 100%;
            font-size: small;
        }
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        width: 100%;
        height: 100%;
        border-radius: 1.5rem;
        border: 1px solid $color-gray-dark;
    }
}