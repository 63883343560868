.event-request {
  width: 25% !important;
  padding: 30px !important;
  min-width: 20%;

  color: $color-blue-dark;

  h1, h2 {
    margin: 0 0 20px 0;

    font-weight: normal;
    line-height: 1.5;
  }

  h2 {
    margin: 20px 0;

    font-size: $font-size-xxl;
  }

  img {
    width: 120px;
  }

  .button {
    margin: 0 5px;
  }
}
@media (max-width: 1280px) {
  .event-request{
    width: 35% !important;
  }
}
@media (max-width: 960px) {
  .event-request{
    width: 50% !important;
  }
}
@media (max-width: 550px) {
  .event-request{
    width: 60% !important;
    h1, h2{
      font-size: 18px;
      font-weight: bold;
    }
  }
}
