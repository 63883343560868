@import '../../../scss/colors';

#sidebar-container {
    .sidebar-nav {
        .nav-item {
            padding-left: 10px;
            margin-bottom: 10px;
            position: relative;
            svg {
                margin-right: 10px;
                color: #fff;
            }
            a,.btn-link {
                padding: 0;
                margin: 0;
                border: none;
                text-align: left;
                span {
                    color: #fff;
                }
            }
            .btn-link:focus {
                box-shadow: unset;
            }
            .btn-round {
                background-color: #fff;
                width: 40px;
                height: 40px;
                padding: 0;
                font-weight: bold;
            }
            .unread-indicator {
                position: absolute;
                top: -9px;
                right: 1px;
                width: 20px;
                height: 20px;
                border-radius: 20px;
                background-color: $color-salmon;
                z-index: 10000;
                text-align: center;
                color: #fff;
                font-weight: bold;
                font-size: .9rem;
            }
        }
        .nav-item.notifications {
            svg {
                color: $color-teal;
            }
        }
        .nav-item.upgrade {
            background-color: $color-slate-extra-dark;
            color: $color-teal;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            padding-top: .5rem;
            padding-bottom: .5rem;
            font-weight: bold;
            font-size: .8rem;
            cursor: pointer;
        }
    }
    .sidebar-nav.top {
        .nav-item {
            svg {
                font-size: 1.75rem;
                width: 60px;
            }
        }
    }
    .sidebar-nav.bottom {
        .nav-item {
            svg {
                font-size: 1.75rem;
                width: 60px;
            }
        }
    }
}
.nav-item-popover {
    display: none !important;
}

@media (min-width: 768px) {
    #sidebar-container {
        .sidebar-nav {
            .nav-item {
                width: 50px;
                text-align: center;
                margin-bottom: 20px;
                a,.btn-link {
                    span {
                        display: none;
                    }
                }
                .btn-round {
                    margin-left: -10px;
                }
                .unread-indicator {
                    font-size: 1rem;
                }
            }
        }
        .sidebar-nav.top {
            .nav-item {
                svg {
                    width: unset;
                    font-size: 2rem;
                }
            }
        }
        .sidebar-nav.bottom {
            .nav-item {
                svg {
                    width: unset;
                    font-size: 1.4rem;
                }
            }
        }
    }
    .nav-item-popover {
        display: unset !important;
        span.content {
            font-weight: 600;
        }
    }
    #user-nav-popover {
        width: 260px;
        .user-nav-name {
            font-weight: 500;
        }
        .user-nav-id {
            font-size: .75rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}

@media (min-width: 1025px) {
    #sidebar-container {
        .sidebar-nav {
            .nav-item {
                width: 62px;
                .unread-indicator {
                    font-size: .9rem;
                }
            }
        }
    }
}