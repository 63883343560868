.view-content {
	margin-top: 15px;
	margin-left: 255px;
	padding-right: 17px;
    width: calc(100% - 275px);
}
#app-container.sidebar-closed #content-container .view-content {
	margin-left: 0;
	width: 100%;
}
.view-header {
	position: sticky;
    top: 63px;
	z-index: 10;
	border: 1px solid $color-gray-medium;
	height: 70px;
	padding: .5rem;
	background-color: white;
	margin-right: 0px;
	.view-title {
		font-size: 1.25rem;
		font-weight: bold;
		position: relative;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
#app-container .view-header {
	top: 0;
}
#app-container.sidebar-closed #content-container .view-header {
	border: none;
}

.view-comp {
    .comp-header {
        .comp-title {
            font-size: x-large;
			font-weight: bold;
        }
	}
	.col.metadata {
        background-color: $color-teal-light;
        border: 1px solid #ccc;
        padding: .5rem;
    }
    .form-label {
        font-weight: bold;
    }
}