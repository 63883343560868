@import '../../scss/colors';

#select-role-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 35vh;
    width: 98%;
    .row {
        padding: 0;
        margin: 0;
        height: 100%;
        .left {
            padding: 0;
            margin: 0;
            height: 200px;
            background-color: $color-blue-dark;
            .container-copy, .container-title {
                color: #fff;
            }
        }
        .right {
            padding: 0;
            margin: 0;
            .container-copy, .container-title {
                color: #000;
            }
        }
    }
    .container-title {
        font-size: 1.5rem;
        font-weight: bold;
    }
    .container-logo {
        position: absolute;
        top: 1rem;
        right: 2rem;
        img {
            width: 180px;
        }
    }
    .container-prompt {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
    }
    .container-hint {
        width: 100%;
        font-size: 1.15rem;
        text-align: center;
    }
    .container-input {
        width: 100%;
        margin-top: 1rem;
        .list-group-item {
            cursor: pointer;
            border: 1px solid rgba($color-gray-medium, 0.5);
            margin-bottom: .5rem;
            min-height: 4rem;
            .item-title {
                font-weight: bold;
                font-size: 1rem;
            }
            .item-description {
                margin-top: .25rem;
            }
        }
        .list-group-item:hover {
            background-color: rgba($color-gray-medium, 0.5);
        }
    }
    .container-cta {
        text-align: end;
        padding-top: 2rem;
        .btn {
            min-width: 8rem;
        }
    }
}

@media (min-width: 768px) {
    #select-role-container {
        height: 100vh;
        .row {
            .left {
                height: 100%;
            }
        }
        .inside-container {
            padding-top: 12rem;
            padding-left: 6rem;
            padding-right: 6rem;
            .container-title {
                font-size: 2rem;
            }
            .container-copy {
                font-size: 1.25rem;
            }
        }
    }
}