.hub-invite {
  margin: 0 auto;
  box-shadow: 0 0 5px 0 #ccc;
  box-sizing: border-box;
  border-radius: 7px;
  width: 50%;
  height: 80vh;
  position: relative;
  text-align: center;
  //border: 1px solid #324a5e;

  &__img {
    margin: 10px;
    padding: 10px;
    height: 55%;
    img {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      //opacity: 0.8;
    }
  }

  &__header {
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
  }

  &__info {
    margin: 20px;
    text-align: left;
    font-size: 10px;
    font-weight: bold;
    height: 20%;

    label {
      font-size: 12px;
      //color: #555555;
      line-height: 1.5;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 15px 25px 0 25px;
      min-height: 55px;
      border: 1px solid #e6e6e6;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
      border-bottom: 0;
    }

    input {
      width: 90%;
      font-size: 16px;
      line-height: 1.2;
      padding: 0 25px;
      height: 55px;
      font-weight: normal;
    }
    input:focus {
      outline: 1px solid #324a5e;
    }
  }

  &__request {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;

    button {
      text-transform: uppercase;
    }
  }
  
  &__error {
    font-size: 12px;
    color: red;
    font-weight: bold;
  }
}

.is-danger {
  outline: 1px solid red;
}

.help {
  font-size: 12px;
  color: red;
}
