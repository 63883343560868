.selectbox {
  display: inline-block;
  min-width: 200px;

  vertical-align: middle;
  border-radius: 4px;

  .Select-control {
    height: 50px;
    font-size: 14px;
    border: 1px solid $color-gray-light;
    border-radius: 4px;
  }

  .Select-placeholder {
    line-height: 50px;
    color: $color-gray-medium;
    font-size: $font-size-m;
  }

  .Select-input > input {
    line-height: 34px;
  }

  .Select-value-label {
    line-height: 50px;
  }

  .Select-clear-zone {
    display: none;
  }

  &.error {
    border: 1px solid red;
  }

  .Select-menu-outer {
    z-index: 32100;
  }
}
