@import '../../scss/colors';

.cold-welcome {
    position:absolute;
    z-index: 999998;
    top: 10rem;
    left: 0;
    right: 0;
    margin: auto;
    width: 70%;
    border-radius: 2rem;
    border: 2px solid $color-blue-dark;
    background-color: #fff;
    text-align: center;
    .cold-welcome-fill {
        background-color: rgba(#87B9E7, 0.12);
        padding: 2rem;
        border-radius: 2rem;
        .cold-open-title {
            font-weight: bold;
            font-size: 1.9rem;
            margin: 1rem;
        }
        .cold-open-title.smaller {
            font-size: 1.5rem;
        }
        .cold-copy {
            font-size: 1.2rem;
            margin: 1rem;
            text-align: center;
        }
        .cold-open-cta {
            text-decoration: underline;
            color: $color-teal-dark;
            cursor: pointer;
            font-weight: bold;
        }
    }
}
@media (min-width: 1025px) {
    .cold-welcome {
        width: 45%;
    }
}