@import '../../scss/colors';

.disabled-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: $color-gray-dark;
    .disabled-content {
        position: absolute;
        left: 0;
        right: 0;
        top: 15%;
        margin: auto;
        width: 40%;
        height: 30%;
        background-color: $color-gray-light;
        border: 1px solid $color-gray-medium;
        border-radius: .25rem;
        padding: 1rem;
        .copy {
            margin-top: 1rem;
        }
        .reason {
            margin-top: .5rem;
        }
        .cta {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: fit-content;
            padding: 1rem;
        }
    }
}