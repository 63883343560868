@import '../../../scss/colors';

#local-navbar-container {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 38px;
    width: 175px;
    height: 35px;
    padding: .5rem;
    overflow-y: hidden;
    overflow-x: hidden;
    //background-color: rgba($color-teal-light, .3);
    background-color: $color-teal-light;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    .nav-bar-welcome {
        position: relative;
         a {
            font-weight: bold;
         }
         div {
             font-weight: bold;
         }
         svg {
             position: absolute;
             top: 0;
             right: 0;
         }
    }
    .nav-bar-groups {
        display: none;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        .nav-bar-group-title {
            cursor: pointer;
            margin-top: 1rem;
            font-weight: 600;
            svg {
                margin-right: .25rem;
            }
        }
        .nav-bar-group {
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            transition: all 0.5s ease;
            .nav-bar-item {
                position: relative;
                padding: .1rem;
                padding-top: .25rem;
                padding-bottom: .5rem;
                span.badge.badge-pill {
                    position: absolute;
                    top: 7px;
                    left: -4px;
                }
                span.badge {
                    position: absolute;
                    top: 3px;
                    left: -6px;
                }
                svg {
                    font-size: 1rem;
                }
                .link-container {
                    margin-left: 1.25rem;
                    a {
                        color: #000;
                    }
                    a:hover {
                        text-decoration: underline;
                    }
                }
            }
            .nav-bar-item.active {
                background-color: $color-teal-light;
            }
        }
        .nav-bar-group.open {
            display: block;
        }
        .nav-bar-group.closed {
            display: none;
        }
    }
}

#local-navbar-container.open {
    height: 100vh;
    overflow-y: auto;
    .nav-bar-groups {
        display: unset;
    }
}

@media (min-width: 768px) {
    #local-navbar-container {
        height: 100vh;
        left: 50px;
        padding: 1rem;
        overflow-y: auto;
        background-color: rgba($color-teal-light, .3);
        .nav-bar-welcome {
            svg {
                display: none;
            }
        }
        .nav-bar-groups {
            display: block;
        }
    }
}

@media (min-width: 1025px) {
	#local-navbar-container {
		width: 240px;
        left: 64px;
	}
}