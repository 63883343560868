#planner-container {
    .nav-link {
        font-size: small;
    }
    .nav-link.active {
        color: #fff;
        background-color: $color-dustblue;
    }
    h5 {
        padding: 1em;
        background-color: $color-gray-light;
        font-weight: bold;
    }
    .card-title {
        font-weight: bold;
    }
    label {
        font-weight: bold;
    }    
    label.form-check-label {
        font-weight: unset;
    }
}

#planner-tabs-tabpane-mespace {
    .table td {
        padding: .25em;
    }
    .dropdown-item {
        font-size: small;
    }
}

#budget-data-table, #proposal-data-table, #space-data-table {
    th, td {
        text-align: center;
    }
    th {
        font-size: smaller;
    }
    td {
        font-size: larger;
    }
    td#commission {
        font-weight: bold;
        color: $color-teal;
    }
    th#hotelname {
        text-align: left;
        max-width: 100px;
    }
}

#planner-question-responses {
    .row {
        border: 1pt solid $color-gray-dark;
        padding: .5rem;
        .row-header {
            font-weight: bold;
        }
    }
    .table-header.row {
        background-color: $color-gray-light;
        div {
            font-weight: bold;
        }
    }
}

#planner-search-filters {
    margin-top: 1rem;
    button {
        width: inherit;
    }
}