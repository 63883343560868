@import '../../scss/colors';

.invitation-modal {
    .modal-title {
        color: $color-teal-dark;
        .form-text {
            color: #000;
            font-size: 1rem;
        }
    }
    .invitation-option {
        cursor: pointer;
        border: 1px solid $color-gray-200;
        border-radius: .2rem;
        padding: .5rem;
        margin: 1rem;
        .col-sm-1 {
            text-align: center;
        }
        .option-title {
            font-size: 1.1rem;
            font-weight: bold;
        }
        svg {
            font-size: 2.2rem;
        }
    }
    .invitation-option:hover {
        background-color: $color-gray-200;
    }
    .invitations {
        .row {
            margin-bottom: .5rem;
        }
        .col-sm-1 {
            svg {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 2px;
                margin: auto;
                font-size: 1.5rem;
            }
        }
    }
}