$body-color: #000;

/* COLOR VARIABLES */
$color-white: #fff;
$color-black: #000;
$color-teal: #4FC0B0;
$color-teal-light: #DCF2EF;
$color-teal-medium: #95D9D0;
$color-teal-dark: #027587;
$color-blue-light: #BCEBFF;
$color-blue-medium: #638FA9;
$color-blue-dark: #052C3F;
$color-slate: #0A587E;
$color-slate-dark: #073d57;
$color-slate-extra-dark: #021b27;
$color-salmon: #F86866 ;
$color-yellow: #FAC536;
$color-orange: #FA6A36;
$color-red: #B82B45;
$color-gray-light: #f0f2f3;
$color-gray-medium: #9faeb5;
$color-gray-dark: #5f7884;
$color-dustblue: #8EACBA;

//bootstrap
$color-gray-100:                        #f9f9fa;
$color-gray-200:                        #e1e6ef;
$color-gray-300:                        #c0cadd;
$color-gray-400:                        #869fac;
$color-gray-500:                        #678898;
$color-gray-600:                        #9faecb;
$color-gray-700:                        #3e515b;
$color-gray-800:                        #384042;
$color-gray-900:                        #151b1e;

$color-atlantis: #92d050;
$color-denim: #146ac4;
$color-dingley: #628c47;
$color-tara: #e1efd8;