@import '../../../scss/colors';

#pipeline-container {
    position: relative;
    margin-top: 1rem;
    min-height: 20rem;
    div.noresults {
        position: absolute;
        z-index: 1000;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.5rem;
        font-weight: bold;
        color: $color-gray-light;
    }
    .col {padding-right: 4px;
        padding-left: 4px;
    }
    .list-group-item + .list-group-item {
        border-top-width: 1px;
    }
    .card {
        border: none;
        .card-header {
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-radius: .5rem;
            background-color: #95D9D0;
            color: #000;
            min-height: 5rem;
            .card-title {
                font-size: 1rem;
                text-align: center;
                font-weight: bold;
            }
        }
        .card-body {
            padding: .25rem;
        }
    }
    .pipeline-card {
        border: 3px solid rgba($color-dustblue, 0.30);
        border-radius: .25rem;
        padding-top: .25rem;
        margin-bottom: .5rem;
        height: 14rem;
        .button-section {
            text-align: end;
            margin-right: -1rem;
            .action-button {
                line-height: 0;
                border-color: #fff;
                font-size: 1rem;
                color: $color-teal;
            }
            .action-button:hover {
                border-color: $color-teal-dark;
                background-color: #fff;
            }
        }
        .title-section {
            a {
                font-size: 1rem;
                font-weight: 400;
                padding: 0;
                text-align: left;
                b.small {
                    font-size: 85%;
                    font-weight: bold;
                }
            }
        }
        .details-section {
            margin-top: 1rem;
            div {
                margin-bottom: .15rem;
                font-size: 0.9rem;
                font-weight: bold;
                color:rgba(0, 0, 0, 0.85);
                span {
                    font-weight:normal;
                }
                svg {
                    border-radius: 1rem;
                    font-size: 1.5rem;
                    margin-right: .75rem;
                }
                svg.on {
                    color: $color-teal;
                    background-color: $color-gray-light;
                }
                svg.off {
                    color: $color-gray-light;
                    background-color: $color-gray-light;
                }
            }
        }
        .indicator-section {
            position: absolute;
            width: 45%;
            height: 1.95rem;
            bottom: .25rem;
            right: .25rem;
            text-align: end;
            .indicator {
                position: relative;
                display: inline-block;
                margin-right: .15rem;
                text-align: center;
            }
            svg {
                cursor: pointer;
                border: 2px solid $color-teal-dark;
                border-radius: 1rem;
                padding: .35rem;
                color: $color-teal-dark;
            }
            .indicator.negative {
                svg {
                    border: 2px solid $color-red;
                    color: $color-red;
                }
            }
            .indicator-badge {
                position: absolute;
                top: -6px;
                right: -6px;
            }
        }
        .code-section {
            font-size: 0.8rem;
            color:rgba(#5F7884, 0.6)
        }
    }
}
@media (min-width: 1025px) {
    #pipeline-container {
        .col {
            padding-right: 15px;
            padding-left: 15px;
        }
        .card {
            .card-header {
                min-height: unset;
                .card-title {
                    font-size: 1.2rem;
                }
            }
        }
        .pipeline-card {
            .indicator-section {
                svg {
                    border-radius: 1rem;
                    padding: .35rem;
                }
            }
        }
    }
}