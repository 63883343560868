@import '../colors';

#search-results-container {
    .hotel-front.map {
        color: #fff;
    }
    .hotel-front {
        position: relative;
        padding: 1rem;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 12px;
        margin-left: 0;
        border-radius: 1rem;
        border: 1px solid $color-gray-medium;
        width: 96%;
        height: 480px;
        cursor: pointer;
        &.unmatched {
            background-color: rgba($color-teal-light, .5);
            cursor: unset;
        }
        .hotel-ranking {
            position: absolute;
            top: -.25rem;
            right: -.25rem;
            z-index: 8;
            img {
                width: 3rem;
                padding: .5rem;
                border-radius: 4rem;
                border: 1px solid $color-slate;
                background-color: $color-teal-light;
            }
        }
        .hotel-image {
            position: relative;
            text-align: center;
            .slick-slide {
                img {
                    width: 99% !important;
                    border: 1px solid gray;
                    border-radius: .2rem;
                }
            }
            img.affiliate-logo {
                width: 50px;
                height: 50px;
                position: absolute;
                bottom: -2px;
                right: 0;
                border: 1px solid #ccc;
                border-radius: 5px;
                background-color: #fff;
            }
        }
        .hotel-title {
            margin-top: .5rem;
            font-size: 1rem;
            font-weight: bold;
            height: 1.5rem;
            overflow: hidden;
        }
        .hotel-details {
            margin-top: .5rem;
            span.detail-label {
                font-weight: bold;
                width: 4.5rem;
            }
            span {
                display: inline-block;
            }
        }
        .hotel-cta {
            text-align: center;
            margin-top: 1.75rem;
        }
        .hotel-rating {
            position: absolute;
            bottom: 3.5rem;
            right: .5rem;
            width: 3.5rem;
            .numerator {
                position: absolute;
                top: 0.4rem;
                left: 0;
                font-size: x-large;
                font-weight: bold;
                color: $color-slate;
            }
            .denominator {
                position: absolute;
                top: 1rem;
                left: 1.1rem;
                color: #000;
            }
            svg {
                position: absolute;
                bottom: -2rem;
                left: 2.5rem;
                font-size: medium;
                color: $color-yellow;
            }
        }
        .hotel-distance {
            text-align: center;
            position: absolute;
            bottom: .6rem;
            left: 0;
            right: 0;
            margin: auto;
            width: 10rem;
        }
        .promo-badge {
            position: absolute;
            bottom: .6rem;
            left: .6rem;
            z-index: 8;
            color: $color-teal;
            .promo-badge-image {
                width: 45px;
            }
        }
    }
}