.radio {
  position: relative;
  display: inline-block;

  width: 30px;
  height: 30px;

  border: 1px solid #CCC;
  border-radius: 20px;
  text-align: center;
  line-height: 27px;

  label:after {
    content: '';
    display: inline-block;

    position: absolute;
    left: 9px;
    top: 9px;

    width: 10px;
    height: 10px;

    background: #FFF;
    border: 1px solid #CCC;
    border-radius: 10px;
    cursor: pointer;
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    opacity: 0;

    cursor: pointer;

    &:checked + label:after {
      background: #628ea8;
    }
  }
}
