.custom-date-picker {
	.react-datepicker__input-container {
		input {
			border: none;
			border-radius: 0;
			text-align: center;
			height: 0;
			outline: none;
			font-size: 12px;
		}
	}

	.date-picker-icon {
		position: absolute;
		top: 11px;
		right: 10px;
		cursor: pointer;
		z-index: 0;
		cursor: pointer;
	}
}
