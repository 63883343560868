@import '../../scss/colors';

div.stacked-bar-container {
    .bar {
        display: inline-block;
        padding-left: .5rem;
        height: 1.25rem;
    }
    .bar.empty {
        width: 100%;
        border-top-left-radius: .5rem;
        border-top-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
        border-bottom-right-radius: .5rem;
        background-color: $color-gray-medium;
        border: 1px solid $color-gray-dark;
    }
    .bar-label {
        font-weight: 600;
        color: $color-gray-dark;
    }
    .bar-percentage {
        color: $color-gray-dark;
    }
}