.checkbox {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;

  vertical-align: middle;

  &__input {
    position: absolute;
    top: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    opacity: 0;

    &:checked + label:after {
      content: '';

      position: absolute;
      left: 4px;
      top: 18px;

      width: 6px;
      height: 6px;

      background: $color-white;
      box-shadow:
        6px 0 0 $color-gray-dark,
        12px 0 0 $color-gray-dark,
        12px -6px 0 $color-gray-dark,
        12px -12px 0 $color-gray-dark,
        12px -18px 0 $color-gray-dark,
        12px -24px 0 $color-gray-dark;
      transform: rotate(45deg);
    }
  }

  &__label {
    position: relative;
    padding: 0;

    cursor: pointer;

    &:before {
      content: '';

      display: inline-block;
      width: 40px;
      height: 40px;
      margin-right: 10px;

      vertical-align: text-top;
      border: 2px solid $color-gray-dark;
      border-radius: 4px;
      background: $color-white;
    }
  }
}

.checkbox_size_s {
  label:before {
    width: 20px;
    height: 20px;
  }

  input {
    &:checked + label:after {
      left: 4px;
      top: 11px;
      width: 3px;
      height: 3px;
      box-shadow:
        3px 0 0 $color-gray-dark,
        6px 0 0 $color-gray-dark,
        6px -3px 0 $color-gray-dark,
        6px -6px 0 $color-gray-dark,
        6px -9px 0 $color-gray-dark,
        6px -12px 0 $color-gray-dark;
    }
  }
}
