@import '../colors';

.date-range-picker-container {
    .form-control {
        font-size: .8rem;
        display: inline-block;
        width: 10rem;
    }
    .form-label {
        margin-left: .5rem;
        min-width: 12rem;
        border: 1px solid $color-gray-medium;
        background-color: rgba($color-gray-medium, 0.25);
        padding: .25rem;
        padding-left: .5rem;
        padding-right: .5rem;
        cursor: pointer;
    }
    .date-range-picker-control {
        position: relative;
        padding: 6px 24px;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        cursor: pointer;
        .fa-calendar {
            position:absolute;
            top: 0;
            bottom: 0;
            left: 6px;
            margin: auto;
        }
        .fa-chevron-down {
            position:absolute;
            top: 0;
            bottom: 0;
            right: 4px;
            margin: auto;
            font-size: 0.8rem;
        }
        .fa-circle-exclamation {
            position:absolute;
            top: 0;
            bottom: 0;
            right: 16px;
            margin: auto;
            color: $color-salmon;
        }
    }
}
.date-range-picker-overlay {
    border: 1px solid $color-gray-medium;
    background-color: #fff;
    z-index: 10000;
    .popper-close {
        position: absolute;
        top: 2px;
        right: 4px;
        color: $color-salmon;
        font-size: 1.1rem;
        cursor: pointer;
    }
    .popper-close:hover {
        background-color: $color-gray-light;
    }
    .popper-ctas {
        margin-bottom: .5rem;
        button {
            font-size: .8rem;
        }
        .col {
            text-align: center;
        }
    }
}