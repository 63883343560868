.rowlabel {
    height: 100%;
    border: 1px solid #ccc;
    border-top: 0;
    padding: 0 10px;
    line-height: 3vh;
    background-color: #DAE3F3;
    font-weight: bold;
}

.rowvalue {
    height: 100%;
    margin-left: 0;
    border: 1px solid #ccc;
    border-left: 0;
    border-top: 0;
    padding: 10px;
}

.meeting-room-group-row {
    border: 1px solid #c2c2c2;
    background-color: $color-gray-light;
    td {
        font-weight: bold;
    }
}

.meeting-room-row {
    cursor: pointer;
    border: 1px solid #c2c2c2;
    &:hover {
        background-color: $color-teal-light;
    }
    td, th {
        border: 1px solid #c2c2c2;
    }
}
.list-reviews-item {
    div:nth-of-type(2) {
        margin-left: 60px;
        font-size: 1.5rem;
        font-weight: bold;
    }
    div:nth-of-type(3) {
        margin-left: 60px;
        font-style: italic;
    }
    div:nth-of-type(4) {
        margin-top: 10px;
        margin-left: 60px;
        font-size: 1.1rem;
    }
}
.modal-body {
    .rating-header {
        margin-bottom: 1rem;
        svg {
            color: $color-yellow;
            font-size: 1.5rem;
            margin-right: 1rem;
        }
        span:nth-of-type(1) {
            font-size: 2rem;
            font-weight: bold;
            margin-right: 1rem;
        }
        span:nth-of-type(2) {
            font-size: 1.5rem;
        }
    }
    .rating {
        margin-bottom: 1rem;
        span:nth-of-type(1) {
            font-size: .75rem;
            font-weight: bold;
            width: 10rem;
            display: inline-block;
        }
        span:nth-of-type(2) {
            height: 1rem;
            width: 5rem;
            display: inline-block;
            background-color: $color-gray-dark;
            span.filler {
                height: 1rem;
                display: inline-block;
                background-color: $color-teal-medium;
            }
        }
        span:nth-of-type(3) {
            margin-left: 1rem;
            font-size: 1rem;
            font-weight: bold;
        }
    }
}
div#expectations-container {
    text-align: center;
    .large.metric {
        font-size: 3rem;
        font-weight: bold;
    }
    .subtitle {
        font-size: 1.5rem;
        font-weight: lighter;
    }
    .chart-legend {
        text-align: center;
        .chart-legend-item {
            margin-bottom: 1.5rem;
            text-align: start;
            height: 1.2rem;
            .indicator {
                display: inline-block;
                border: 1px solid $color-gray-medium;
                width: 1rem;
                height: 1rem;
            }
            .label {
                display: inline-block;
                font-size: 1.2rem;
                margin-left: 1rem;
            }
        }
    }
}
.initials-badge {
    float: left;
    text-align: center;
    padding-top: .6rem;
    font-size: 1.25rem;
    font-weight: bold;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: $color-teal-dark;
    color: white;
}