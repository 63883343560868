.row.row-equal {
  padding-right: ($grid-gutter-width / 4);
  padding-left:  ($grid-gutter-width / 4);
  margin-right: ($grid-gutter-width / -2);
  margin-left:  ($grid-gutter-width / -2);

  [class*="col-"] {
    padding-right: ($grid-gutter-width / 4);
    padding-left:  ($grid-gutter-width / 4);
  }
}

.main .container-fluid {
  padding-top: 0px;
  padding-right: 30px;
  padding-bottom: 0px;
  padding-left: 10px;
}

@media (min-width: 550px) {
  .main .container-fluid {
    padding-left: 200px;
  }
  .sidebar-minimized .main .container-fluid {
    padding-left: 50px;
  }
}