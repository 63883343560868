@import '../colors';

#promotion-details-container {
    position: relative;
    img {
        width: 250px;
        min-height: 174px;
        margin: .5rem;
        border-radius: .5rem;
    }
    .headline {
        font-weight: bold;
        color: $color-teal-dark;
        padding: .5rem;
    }
    .details {
        position: absolute;
        top: 0;
        left: 260px;
        height: 6.2rem;
        .description {
            padding: .5rem;
            width: 80%;
            height: 100%;
            overflow: hidden;
        }
    }
    .promo-badge {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 8;
        color: $color-teal;
        .promo-badge-image {
            width: 50px;
            height: revert;
        }
    }
    .dates-match {
        position: absolute;
        top: .2rem;
        right: 75px;
        padding: .25rem .5rem;
        border-radius: .25rem;
        background-color: $color-teal-dark;
        color: #fff;
        font-size: small;
        font-weight: bold;
        font-style: italic;
    }
    .dates {
        position: absolute;
        bottom: 1rem;
        left: 260px;
    }
    .cta-container {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: .5rem;
        .btn {
            font-size: small;
            font-weight: bold;
            margin: .5rem;
        }
    }
}