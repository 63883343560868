#contractClausesContainer {
    border: none;
    .card-body {
        border: none;
        min-height: 25rem;
        .row {
            margin-left: 2rem;
        }
        .btn-choice {
            width: 90%;
            min-height: 6rem;
        }
        .number-input {
            width: 4rem;
            text-align: right;
            margin-left: .25rem;
            margin-right: .25rem;
        }
        .card-body {
            min-height: unset;
        }
        .tab-pane {
            .row {
                margin-left: unset;
            }
        }
    }
    .tab-content {
        margin-top: 11px;
        margin-left: -0.625rem;
    }
    .card-footer {
        border: none;
        background-color: unset;
        text-align: center;
    }
    .headText {
        margin: 1rem;
        font-size: large;
        font-weight: bold;
    }
    .subText {
        margin: 1.5rem;
    }
    .clause-selector {
        font-size: large;
    }
    .clause {
        .row { margin-left: unset;}
        padding: .5rem;
        border-radius: 1rem;
        min-height: 13rem;
        max-height: 13rem;
        margin-bottom: 1rem;
        &.suggested {
            border: 1px solid #cdcdcd;
        }
        &.selected {
            border: 1px solid $color-teal;
            box-shadow: 2px 3px 12px 0px black;
        }
    }
    .clause-desc
    {
        min-height: 8rem;
        max-height: 8rem;
        overflow: hidden;
    }
    .clause-name {
        font-weight: bold;
        font-size: larger;
    }
    .clause-more {
        min-height: 1rem;
        text-align: end;
        font-size: smaller;
    }
}