@import '../../scss/colors';

div.donut-chart-container {
    position: relative;
    width: 100%;
    height: 100%;
    .metric-callout {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 45%;
        width: 50%;
        &.no-legend {
            left: 0;
            right: 0;
        }
        &.wide {
            width: 70%;
        }
        .metric.large {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 2.5rem;
            font-weight: bold;
        }
        .metric.label {
            padding: 0;
            display: block;
            width: 100%;
            text-align: center;
            font-size: .9rem;
            margin-top: -0.5rem;
            color: $color-gray-dark;
        }
    }
}