.textarea {
  height: 100px;
  padding: 6px 12px;

  font-size: $font-size-m;
  line-height: $font-line-height;
  color: $color-gray-medium;

  vertical-align: middle;
  border: 1px solid $color-gray-light;
  border-radius: 4px;
  background-image: none;
  box-shadow: none;
  box-sizing: border-box;
}
