@import '../../scss/colors';

#event-plan-duplicate-modal {
    .modal-content {
        .modal-body {
            .list-group {
                .list-group-item {
                    cursor: pointer;
                }
                .list-group-item:hover {
                    background-color: rgba($color-teal-light, 0.8);
                }
            }
        }
    }
}