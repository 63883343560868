.datepicker {
  position: relative;
  display: inline-block;
  border-radius: 4px;
  background-color: $color-white;

  &.error {
    border: 1px solid red;
  }
  
  &__icon {
    position: absolute;
    top: 14px;
    right: 10px;
    cursor: pointer;
    z-index: 0;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__input-container {
    width: 100%;

    input {
      width: 100%;
      background: none;
    }
  }
}

.datepicker > div {
  display: inline-block;
  width: 100%;

  position: relative;
}
