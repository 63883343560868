input[type=date] {
    font-size: .9rem;
}

.places-dropdown-container:empty {
    display: none;
}

.places-dropdown-container {
    color: $secondary;
    position: absolute;
    border-radius: 3px;
    border: 1pt solid $color-gray-medium;
    background-color: $color-white;
    z-index: 9999 !important;
    box-shadow: 2px 2px 5px #ccc;
    overflow: hidden;
}

.places-suggestion-item {
    text-overflow: ellipsis;
}

.quill {
    height: 80%;
}

.whats-this {
    cursor: help;
    color: $secondary;
    font-size: small;
}

div.custom-tooltip {
    border-radius: 3px;
    border: 1pt solid $color-gray-medium;
    background-color: $color-white;
    padding-top: .5em;
    padding-bottom: .1em;
    padding-left: .5em;
    padding-right: .5em;
    text-align: center;
}

div#info-bar {
    background-color: lightgoldenrodyellow;
    padding: .25rem;
    margin: .5rem;
    text-align: center;
    border-color: gray;
    border-style: solid;
    border-width: thin;
    cursor: pointer;
}

svg.ratingstar {
    color: $color-yellow;
}

span.autoSaveStatus {
    font-size: small;
    font-style: italic;
    color: rgba($color: #000000, $alpha: 0.5);
    display: block;
}

.card-subtitle {
    font-size: small;
}
.daySelector {
    border: 1px solid $color-gray-medium;
    font-weight: bold;
    text-align: center;
    .daySelector-day {
        border: 1px solid $color-gray-medium;
        background-color: $color-gray-light;
        color: #000;
        cursor: pointer;
    }
    .daySelector-day.selected {
        background-color: $color-teal-dark;
        color: #fff;
    }
    .daySelector-day.selected:hover {
        background-color: $color-gray-light;
        color: #000;
    }
    .daySelector-day:hover {
        background-color: $color-teal-dark;
        color: #fff;
    }
}
label.error {
    color: $color-salmon;
}
select.form-control.is-invalid, select.form-control.is-valid {
    background-position: right calc(0.375em + 0.6875rem) center;
}
.form-label {
    font-weight: 600;
}
#location-selector-inputs {
    .input-group {
        .input-group-prepend {
            .input-group-text {
                background-color: $color-blue-dark;
                width: 45px;
                justify-content: center;
                font-size: 1.5rem;
                svg {
                    color: #fff;
                }
            }
        }
    }
}
#warning-banner {
    .warning-banner-container {
        position: relative;
        background-color: inherit;
        svg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 4px;
            margin: auto;
            font-size: 4rem;
        }
        .warning-banner-message {
            position: absolute;
            top: -0.4rem;
            left: 5rem;
            font-size: 1rem;
            background-color: inherit;
            .btn-link {
                padding: 0;
                color: inherit;
                text-decoration: underline;
            }
        }
    }
    .warning-banner-container.small {
        min-height: 4rem;
        svg {
            left: 2px;
            font-size: 2.5rem;
        }
        .warning-banner-message {
            left: 3.5rem;
        }
    }
    .warning-banner-container.with-heading {
        min-height: 6rem;
        .alert-heading {
            position: absolute;
            top: 0.7rem;
            left: 5rem;
            font-size: 2rem;
        }
        .warning-banner-message {
            top: 3.2rem;
        }
    }
    .warning-banner-container.small.with-heading {
        min-height: 4.5rem;
        .alert-heading {
            left: 3.5rem;
            font-size: 1.25rem;
        }
        .warning-banner-message {
            top: 2.7rem;
            left: 3.5rem;
        }
    }
    .warning-banner-container.with-prompt {
        min-height: 5rem;
    }
    .warning-banner-container.small.with-prompt {
        min-height: 4rem;
    }
    .warning-banner-container.with-prompt.with-heading {
        min-height: 6rem;
    }
    .warning-banner-container.small.with-prompt.with-heading {
        min-height: 5.5rem;
    }
}
.recharts-tooltip-wrapper {
    background-color: #fff;
    z-index: 999999;
}