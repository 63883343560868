.footer {
  margin-top: 10px;
  padding-left: 250px;

  color: #324a5e;
  font-size: 12px;
  line-height: 24px;

  &__logo {
    display: inline-block;
    height: 100px;
    margin-bottom: 10px;

    img {
      height: 100%;
    }
  }
  &__desc{
    font-weight: bold;
    font-style: italic;
    margin-bottom: 20px;
  }
  &__list {
    margin: 0;
    padding: 0;

    list-style-type: none;

    li {
      position: relative;

      padding-left: 28px;
      margin-bottom: 10px;

      .fa {
        position: absolute;
        top: 4px;
        left: 0;

        line-height: 21px;
        font-size: 14px;
      }

      a {
        color: #324a5e;
        text-decoration: none;
      }
    }
  }

  &__links-list {
    li {
      padding: 0;
    }
    li:hover {
      a {
        color: $color-teal;
      }
    }
  }

  &__title {
    margin-bottom: 15px;

    font-size: 20px;
    line-height: 27px;
    font-weight: 500;
    color: #222222;
  }

  &__separator {
    margin-top: 50px;

    border-top: 1px solid lightgray;
  }

  &__copyright {
    height: 80px;

    line-height: 80px;

    a {
      margin-left: 5px;

      color: #324a5e;
    }

    li {
      display: inline-block;
      padding: 0;
    }
  }

  &__social {
    display: inline-block;

    width: 35px;
    height: 35px;
    margin-left: 10px !important;

    border: 1px solid #324a5e;
    text-align: center;
    line-height: 35px;
    border-radius: 20px;
  }
  &__social:hover{
      border: white;
      color: white;
      background-color: #324a5e;
  }
}
