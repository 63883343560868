@import '../colors';

#promotion-details-modal {
    .modal-body {
        min-height: 35rem;
        .row {
            margin-bottom: 1.5rem;
        }
    }
    img {
        width: 250px;
        height: 175px;
        margin: .5rem;
        border-radius: .5rem;
    }
    .headline {
        font-size: large;
        font-weight: bold;
        color: $color-teal-dark;
        padding: .5rem;
    }
    .section-header {
        margin-top: 1.5rem;
        font-weight: bold;
        font-size: large;
    }
    .description {
        padding: .5rem;
    }
    .dates {
        position: absolute;
        left: 25%;
        bottom: 0;
        padding-top: .5rem;
        padding-bottom: 1rem;
    }
}