.button {
  padding: 13px 19px;

  line-height: 14px;
  font-size: $font-size-m;

  outline: none;
  border-radius: 3px;
  border: 2px solid $color-white;
  letter-spacing: 0.4px;
  background: #FFF;

  &__icon {
    margin-left: 6px;
  }

  &__text {
    vertical-align: middle;
  }

  &_type_disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &_type_empty {
    color: $color-gray-dark;
    border-color: $color-gray-dark;
  }

  &_type_empty:hover {
    color: $color-white;
    border-color: $color-white;
    background-color: $color-blue-dark;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
  }

  &_type_empty:active {
    color: $color-white;
    border-color: $color-white;
    background-color: $color-gray-dark;
  }

  &_type_popup {
    color: $color-gray-dark;
    border-color: $color-gray-dark;
    width: 100px;
  }

  &_type_popup:hover {
    color: $color-white;
    border-color: $color-white;
    background-color: $color-blue-dark;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
  }


  &_type_action {
    padding: 13px;

    background-color: $color-teal-dark;
    color: $color-white;
    border-color: $color-teal-dark;
  }

  &_type_thin {
    padding: 7px 5px;

    background-color: $color-gray-dark;
    color: $color-white;
    border-color: $color-gray-dark;
  }
  &_type_thin:hover {

    background-color: $color-blue-dark;
    color: $color-white;
    border-color: $color-blue-dark;
  }

  &_type_fat {
    //padding: 7px 5px;
    min-width: 200px;
    background-color: $color-gray-dark;
    color: $color-white;
    border-color: $color-gray-dark;
  }
  &_type_fat:hover {

    background-color: $color-blue-dark;
    color: $color-white;
    border-color: $color-blue-dark;
  }

  &_type_disabled {
    //padding: 7px 5px;
    min-width: 200px;
    background-color: $color-gray-dark;
    color: $color-white;
    border-color: $color-gray-dark;
    opacity: 0.7;
  }

  &_type_icon {
    padding: 5px;

    background: none;
    border: none;

    .button__icon {
      margin: 0;

      font-size: $font-size-xl;
      color: $color-blue-dark;
    }
  }

  &_type_clear {
    padding: 0;

    border: none;
    background: none !important;

    .button__icon {
      margin: 0 6px;

      font-size: 22px;
      vertical-align: middle;
    }
  }
  &_type_clear:hover {
    color: $color-orange;
  }
  &_type_clear_back {
    padding: 0;

    border: none;
    background: none !important;

    .button__icon {
      margin: 0 6px;

      font-size: 22px;
      vertical-align: middle;
    }
  }

  &_type_clear_back:hover {
    color: #00a1f1;
  }

  &_style_orange {
    color: $color-orange;
    border-color: $color-orange;
    background-color: $color-white;
  }

  &_style_orange:hover {
    color: $color-white ;
    border-color: $color-white ;
    background-color: $color-orange;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
  }

  &_style_gulf {
    color: $color-blue-dark;
  }
}

.btn-round {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  
  &:after {
    display: none;
  }
}