#proposal-overview {
    margin-bottom: 1em;
    
    .form-label {
        font-weight: bold;
    }

    .field-header {
        text-align: center;
        font-size: small;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.85);
    }
}

div#proposal-tabs-tabpane-overview {
    div.card-header {
        font-weight: bold;
    }

    div.form-group label.form-label {
        font-weight: bold;
    }
}

div#supplier-site-criteria {
    label.form-label {
        font-size: smaller;
        margin-bottom: .1rem;
    }
    input {
        font-size: small;
    }
}

div#hh-proposal-form-body {
    label.form-label {
        font-size: smaller;
    }
}

#proposalContactForm {
    .form-label {
        font-weight: bold;
    }
}