.item-view {
  padding: 10px;

  &__thumb-wrapper {
    max-height: 320px;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
  }

  &__thumb {
    width: 100%;
  }

  &__close-x {
    position: absolute;
    text-align: right;
    right: 28px;
    display: inline-block;
    z-index: 1;
    text-shadow: 1px 1px 4px black;
  }

  &__close {
    text-align: right;
    margin-bottom: 5px;
  }

  &__close-link {
    font-size: $font-size-sm;
    letter-spacing: 0.5px;
    text-decoration: none;
    color: $color-orange;
    cursor: pointer;
  }

  &__title {
    margin: 10px 0;
    position: relative;
    font-weight: normal;
  }

  &__contact {
    margin: 7px 0;

    a {
      margin-left: 5px;

      text-decoration: none;
      color: $color-black;
    }
  }

  &__guest-comment {
    padding: 10px;

    font-style: italic;
    font-size: $font-size-sx;
    background-color: $color-tara;
    color: $color-dingley;
    border: 1px solid $color-dingley;
    border-radius: 5px;
  }

  &__description-title {
    margin-bottom: 10px;
    margin-top: 21px;
    font-size: $font-size-xm;

    font-weight: normal;
  }

  &__description {
    letter-spacing: 0.5px;
    line-height: 20px;
    text-align: justify;
    font-size: 10pt;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 50;
    -webkit-box-orient: vertical;
  }
  &__addressbox {
    padding-left: 10px;
  }

  &__facts {
    height: 100%;
    padding: 10px;
    margin-top: 10px;
    border-top: 1px solid $color-gray-medium;
    background: $color-white;
  }

  &__facts-title {
    margin: 0px 0px 10px 10px;
    color: $color-blue-dark;
    font-size: $font-size-xm;
    font-weight: normal;
    text-align: center;
  }

  &__list {
    margin: 0;
    padding: 0;

    list-style-type: none;

    li {
      margin: 5px 0;
    }

    .icon {
      display: inline-block;

      width: 35px;
      padding: 0 10px;

      text-align: center;
      font-size: $font-size-xm;
    }
  }

  &__list-link {
    padding-right: 10px;

    color: $color-denim;
    text-decoration: none;
    font-size: 15px;
  }

  &__list-val {
    color: $color-gray-medium;
    font-size: 15px;
  }

  &__list-label {
    color: $color-blue-dark;
    font-size: 15px;
    padding-right: 10px;
  }

  address {
    font-style: normal;
  }

  .dv-star-rating {
    margin-bottom: 5px;
  }
}

.item-view:hover {
  cursor: pointer;
}

.invite {
  position: absolute;
  right: 0;
}

.slick-slider__popup {
  height: 320px !important;
}

.slick-slider__popup img {
  height: 320px;
}

@media (max-width: 1120px) {
  .item-view {
    &__title {
      font-size: 18px;
      font-weight: bold;
    }
    &__description-title {
      display: none;
    }
    &__description {
      display: none;
    }

    .five.columns {
      width: 100%;
    }
    .seven.columns {
      width: 100%;
      margin-left: 0px;
    }
    &__close-x {
      width: 92%;
    }
  }
}
@media (max-width: 736px) {
  
  .item-view {
    &__title {
      font-size: 14px;
      font-weight: bold;
    }
    &__contact {
      font-size: 12px;
      line-height: 14px;
    }
    & address {
      font-size: 12px;
      line-height: 14px;
    }
    &__facts-title {
      font-size: 14px;
      font-weight: bold;
    }
    &__list-label {
      font-size: 14px;
    }
    &__list-val {
      font-size: 14px;
    }
    &__list .icon {
      font-size: 16px;
    }
    &__close-x {
      width: 90%;
    }
  }
}
